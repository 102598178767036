<script>
import LogoutAlert from "../components/Modals/LogoutAlert.vue";

export default {
  components: {
    LogoutAlert,
  },

  data: () => ({
    isLogoutAlert: false,
    drawer: true,
  }),

  watch: {
    "$i18n.locale"(v) {
      this.$bStore.setLocal("lang", v);
    },
  },

  computed: {
    chats: (vm) => vm.$store.getters.getThreads,
    newMessageNotifis: (vm) => vm.$store.getters.getNewMessageNotifis,
    user: (vm) => vm.$store.getters.getUser,

    getFullName() {
      let name = this.user.user.first_name;
      let lastName = this.user.user.last_name;

      if (window.outerWidth < 840) return null;
      if (!name?.trim()) return null;

      return `${name}${lastName ? ` ${lastName}` : ""}`;
    },
  },

  beforeDestroy() {
    this.$store.dispatch("setThreadId", 0);
  },

  methods: {
    onThemeChange() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$bStore.setLocal("$vuetify.theme.dark", this.$vuetify.theme.dark);
    },

    getCount(item) {
      let old = this.newMessageNotifis.find((x) => x.id == item.id);
      return old ? old.count : 0;
    },
  },
};
</script>

<template>
  <div class="h-100">
    <v-app-bar
      :color="$vuetify.theme.dark ? '' : 'primary'"
      elevation="3"
      app
      clipped-left
      dark
    >
      <v-badge
        :content="newMessageNotifis.length"
        :value="newMessageNotifis.length"
        color="red"
        overlap
      >
        <v-app-bar-nav-icon @click="drawer = !drawer" />
      </v-badge>
      <router-link
        tag="span"
        to="/home"
        class="c-pointer title d-flex ml-3 mr-10"
      >
        <img src="/img/icons/favicon-32x32.png" alt="s" />
        <span class="pl-3 logo-name">GoPharm</span>
      </router-link>
      <v-spacer v-if="!$vuetify.breakpoint.mdAndUp" />
      <div @click="$router.replace('/searchDrugs')" class="py-1">
        <v-text-field
          v-if="$vuetify.breakpoint.mdAndUp"
          rounded
          readonly
          append-icon="mdi-magnify"
          flat
          dense
          hide-details
          :label="$t('Search for drugs...')"
          solo-inverted
        />
        <v-btn v-else dark icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </div>
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
      <v-btn to="/ordersStore" text>
        {{ $t("Orders") }}
      </v-btn>
      <v-menu min-width="260">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-btn
            :icon="!getFullName"
            :color="getFullName ? 'transparent' : ''"
            v-bind="attrs"
            v-on="menu"
            class="ml-2"
          >
            <v-icon>mdi-account-cog</v-icon>
            <template v-if="getFullName">
              <span class="pl-2" v-text="getFullName" />
            </template>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group v-model="$vuetify.theme.dark">
            <v-list-item @click="onThemeChange" :value="true">
              <v-list-item-avatar>
                <v-icon v-if="$vuetify.theme.dark">
                  mdi-white-balance-sunny
                </v-icon>
                <v-icon v-else> mdi-weather-night </v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ $t("darkMode") }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
          <v-divider />
          <v-subheader>{{ $t("languageOfApp") }}</v-subheader>
          <v-list-item-group v-model="$i18n.locale">
            <v-list-item value="uz">
              <v-list-item-avatar>
                <v-img src="../assets/img/flags/uz.png" />
              </v-list-item-avatar>
              <v-list-item-title>O'zbek tili</v-list-item-title>
            </v-list-item>
            <v-list-item value="ru">
              <v-list-item-avatar>
                <v-img src="../assets/img/flags/ru.png" />
              </v-list-item-avatar>
              <v-list-item-title>Русский язык</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
          <v-divider />
          <v-list-item @click="isLogoutAlert = true">
            <v-list-item-avatar>
              <v-icon>mdi-lock</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <LogoutAlert v-model="isLogoutAlert" />

    <v-navigation-drawer
      app
      clipped
      v-model="drawer"
      :permanent="false"
      fixed
      mobile-breakpoint="660"
    >
      <v-list dense class="py-0">
        <template v-for="item in chats">
          <v-list-item :key="item.title" :to="`/chatMessages/${item.id}`">
            <v-list-item-icon class="mr-3">
              <!-- <v-img :src="item.avatar"  alt="0" /> -->
              <v-icon>mdi-account</v-icon>
              <!-- <v-img
              style="width:40px;height:40px"
              class=""
              src="../../public/default.png"
              alt="01"
            /> -->
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-html="item.subject" />
            </v-list-item-content>
            <v-list-item-action>
              <v-badge
                :content="getCount(item)"
                :value="getCount(item)"
                color="primary"
                overlap
              />
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-main style="padding-top: 85px" class="h-100">
      <v-container class="h-100 pb-0" fluid>
        <router-view />
      </v-container>
    </v-main>
  </div>
</template>

<style scoped>
.theme--light .card-text {
  color: black;
}

@media only screen and (max-width: 410px) {
  .logo-name {
    display: none;
  }
}

.mapCard {
  border: 1px solid;
}
</style>
