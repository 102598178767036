<script>
import { yandexMap, ymapMarker } from "vue-yandex-maps";
import sysConfig from "../utils/staticConfig.js";

export default {
  components: { yandexMap, ymapMarker },
  props: {
    markerId: {
      type: [String, Number],
      required: true,
    },
    coords: {
      type: Array,
      required: true,
    },
    isMarker: {
      type: [Boolean, Number],
      default: () => false,
      required: false,
    },
    zoom: {
      type: [String, Number],
      default: () => 13,
      required: false,
    },
  },
  data: () => ({
    settings: {
      apiKey: sysConfig.yandexApi,
      lang: "ru_RU",
      coordorder: "latlong",
      version: "2.1",
    },
  }),

  computed: {
    _coords: {
      get: (vm) => vm.coords,
      set(coords) {
        this.$emit("coords", coords);
      },
    },
  },

  methods: {
    onClick(e) {
      this._coords = e.get("coords");
    },
  },
};
</script>

<template>
  <yandex-map
    class="h-100 w-100"
    :zoom="zoom"
    :settings="settings"
    :coords="_coords"
    @click="onClick"
  >
    <ymap-marker v-if="isMarker" :coords="_coords" :markerId="markerId" />
  </yandex-map>
</template>

<style scoped>
.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}
</style>
