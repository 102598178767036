<script>
import DriversWatch from "../components/Modals/drivers-watch.vue";
import LogoutAlert from "../components/Modals/LogoutAlert.vue";

export default {
  components: {
    LogoutAlert,
    DriversWatch,
  },

  data: () => ({
    menuDrawer: false,
    isLogoutAlert: false,
    minSum: 0,

    isDriversWatch: false,
  }),

  computed: {
    user: (vm) => vm.$store.getters.getUser,
    newMessageNotifis: (vm) => vm.$store.getters.getNewMessageNotifis,
    orderToAdd: (vm) => vm.$store.getters.getOrderToAdd,
    getFullName() {
      let name = this.user.user.first_name;
      let lastName = this.user.user.last_name;

      if (window.outerWidth < 840) return null;
      if (!name?.trim()) return null;

      return `${name}${lastName ? ` ${lastName}` : ""}`;
    },
  },

  created() {
    this.axios.get("order-minimum").then((res) => {
      this.minSum = parseInt(res.data.min) || 0;
    });
  },

  watch: {
    "$i18n.locale"(v) {
      this.$bStore.setLocal("lang", v);
    },
  },

  methods: {
    onThemeChange() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$bStore.setLocal("$vuetify.theme.dark", this.$vuetify.theme.dark);
    },

    onLogOut() {
      this.isLogoutAlert = true;
      // this.$bStore.removeUser();
      // this.$router.replace({ name: "Login" });
    },

    isManager() {
      return !!this.user.user.groups.find((x) => x.name == "manager");
    },

    ordersCount() {
      return this.orderToAdd.drugs.length;
    },

    getFullTotal() {
      let drugs = this.orderToAdd.drugs.map((x) => x.qty * x._price);
      return drugs.reduce((a, b) => a + b, 0);
    },

    onReload() {
      window.location.reload();
    },
  },
};
</script>

<template>
  <div>
    <v-app-bar
      :color="$vuetify.theme.dark ? '' : 'primary'"
      elevation="3"
      app
      clipped-left
      dark
    >
      <!-- <v-app-bar-nav-icon @click="menuDrawer = !menuDrawer" /> -->
      <span
        @click="onReload"
        class="go-logo c-pointer title d-flex ml-3 mr-md-10"
      >
        <img src="/img/icons/favicon-32x32.png" alt="logo" />
        <span class="pl-3">GoPharm</span>
      </span>
      <template v-if="isManager()">
        <v-spacer v-if="!$vuetify.breakpoint.mdAndUp" />

        <div @click="$router.push('/searchDrugs')" class="py-1">
          <v-text-field
            v-if="$vuetify.breakpoint.mdAndUp"
            rounded
            readonly
            append-icon="mdi-magnify"
            flat
            dense
            hide-details
            :label="$t('Search for drugs...')"
            solo-inverted
          />
          <v-btn v-else dark icon>
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </div>
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />

        <v-btn to="/home" text>
          {{ $t("Orders") }}
        </v-btn>

        <v-btn
          v-if="isManager"
          class="ml-2"
          @click="isDriversWatch = true"
          icon
        >
          <v-icon> mdi-truck-cargo-container </v-icon>
        </v-btn>

        <v-badge
          :content="orderToAdd.drugs.length"
          :value="orderToAdd.drugs.length"
          color="red"
          overlap
          offset-x="20"
          offset-y="20"
        >
          <v-btn
            class="ml-2"
            :disabled="ordersCount() == 0 || getFullTotal() < minSum"
            to="/cart"
            icon
          >
            <v-icon> mdi-cart-outline </v-icon>
          </v-btn>
        </v-badge>

        <v-badge
          offset-x="20"
          offset-y="20"
          :content="newMessageNotifis.length"
          :value="newMessageNotifis.length"
          color="red"
          overlap
        >
          <v-btn to="/chat" icon class="ml-2">
            <v-icon> mdi-email-outline </v-icon>
          </v-btn>
        </v-badge>
      </template>

      <v-spacer v-else />

      <v-menu>
        <template v-slot:activator="{ on: menu, attrs }">
          <v-btn
            :icon="!getFullName"
            :color="getFullName ? 'transparent' : ''"
            v-bind="attrs"
            v-on="menu"
            class="ml-2"
          >
            <v-icon>mdi-account-cog-outline</v-icon>
            <template v-if="getFullName">
              <span class="pl-2" v-text="getFullName" />
            </template>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group v-model="$vuetify.theme.dark">
            <v-list-item @click="onThemeChange" :value="true">
              <v-list-item-avatar>
                <v-icon v-if="$vuetify.theme.dark">
                  mdi-white-balance-sunny
                </v-icon>
                <v-icon v-else> mdi-weather-night </v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ $t("darkMode") }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
          <v-divider />
          <v-subheader>{{ $t("languageOfApp") }}</v-subheader>
          <v-list-item-group v-model="$i18n.locale">
            <v-list-item value="uz">
              <v-list-item-avatar>
                <v-img src="../assets/img/flags/uz.png" />
              </v-list-item-avatar>
              <v-list-item-title>O'zbek tili</v-list-item-title>
            </v-list-item>
            <v-list-item value="ru">
              <v-list-item-avatar>
                <v-img src="../assets/img/flags/ru.png" />
              </v-list-item-avatar>
              <v-list-item-title>Русский язык</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
          <v-divider />
          <v-list-item @click="onLogOut">
            <v-list-item-avatar>
              <v-icon>mdi-lock-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-if="false" app clipped fixed v-model="menuDrawer">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title"> Application </v-list-item-title>
          <v-list-item-subtitle> subtext </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item v-for="item in items" :key="item.title" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn @click="onLogOut" block>{{ $t("logout") }}</v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <LogoutAlert v-model="isLogoutAlert" />

    <drivers-watch v-if="isDriversWatch" @close="isDriversWatch = false" />

    <v-main style="padding-top: 85px">
      <router-view />
    </v-main>
  </div>
</template>

<style scoped>
@media only screen and (max-width: 460px) {
  .go-logo span {
    display: none;
  }
}

@media only screen and (max-width: 355px) {
  .go-logo {
    display: none !important;
  }
}
</style>
