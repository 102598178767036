const tools = {
  getRealDate(days, month) {
    let date = new Date();

    if (days) {
      date.setDate(date.getDate() + days);
    }

    if (month) {
      date.setMonth(date.getMonth() + month);
    }

    return date.toISOString().substr(0, 10);
  },

  getRealDateTime() {
    let date = new Date();
    let year = date.getFullYear();

    let month = date.getMonth().toString().padStart(2, "0");

    let day = date.getDate().toString().padStart(2, "0");

    let hours = date.getHours().toString().padStart(2, "0");

    let minutes = date.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  },

  formatDate(date) {
    if (!date || (date && date.length > 10)) return null;
    let [year, month, day] = date.split("-");
    return `${year}-${month}-${day}`;
  },

  strToNum(strNum) {
    return Number(strNum) ? Number(strNum) : 0;
  },

  getUniq(id = "uniq") {
    let uniq = Date.now().toString(36) + Math.random().toString(36).substr(2);

    return `${id}_${uniq}`;
  },

  openImg(url) {
    if (url) {
      window.open(url, "_blank");
    }
  },
};

import Vue from "vue";

export default tools;
Vue.prototype.$tools = tools;
