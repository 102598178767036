import browserStore from "../service/browserStore.js";
// import i18n from "../i18n.js";

const newOrderToAdd = {
  type: "shipping",
  full_name: null,
  phone: "",
  store_id: null,
  payment_type: null,
  shipping_time: null,
  location: "",
  address: null,
  card_pan: null,
  card_exp: null,
  card_type: null,
  card_save: null,
  phone_number: null,
  drugs: [],
  device: "operator",
  comment: "",
};

export default {
  state: {
    selectedDrugs: [],
    orderToAdd: { ...newOrderToAdd },
    orderToAddNum: 0,
  },

  mutations: {
    setOrderToAddNum(state, orderToAddNum) {
      state.orderToAddNum = orderToAddNum;
    },

    setSelectedDrugs(state, selectedDrugs) {
      state.selectedDrugs = selectedDrugs;
      browserStore.setSession("selectedDrugs", state.selectedDrugs);
    },

    setOrderToAdd(state, orderToAdd) {
      state.orderToAdd = orderToAdd;
      browserStore.setSession("orderToAdd", state.orderToAdd);
    },

    addSelectedDrug(state, drug) {
      let old = state.selectedDrugs.find((x) => x.id == drug.id);
      if (old) return;

      if (Array.isArray(state.selectedDrugs)) {
        state.selectedDrugs.unshift(drug);
      } else {
        state.selectedDrugs = [drug];
      }
      browserStore.setSession("selectedDrugs", state.selectedDrugs);
    },

    removeSelectedDrug(state, drugId) {
      state.selectedDrugs = state.selectedDrugs.filter((x) => x.id != drugId);

      let drugs = state.orderToAdd.drugs;
      state.orderToAdd.drugs = drugs.filter((x) => x.drug != drugId);

      browserStore.setSession("orderToAdd", state.orderToAdd);
      browserStore.setSession("selectedDrugs", state.selectedDrugs);
    },

    addToCart(state, drug) {
      let drugs = state.orderToAdd.drugs;
      state.orderToAdd.drugs = drugs = Array.isArray(drugs) ? drugs : [];
      state.selectedDrugs = state.selectedDrugs.map((x) => {
        if (x.id == drug.id) x.qty = drug.qty;
        return x;
      });

      if (drug.qty > 0) {
        let old = drugs.find((x) => x.drug == drug.id);
        if (old) {
          state.orderToAdd.drugs = drugs.map((x) => {
            if (x.drug == drug.id) x.qty = drug.qty;
            return x;
          });
        } else {
          state.orderToAdd.drugs.unshift({
            drug: drug.id,
            qty: drug.qty,
            _name: drug.name,
            _price: drug.price,
            _image_thumbnail: drug.image_thumbnail,
          });
        }
      } else {
        state.orderToAdd.drugs = drugs.filter((x) => x.drug != drug.id);
      }

      browserStore.setSession("orderToAdd", state.orderToAdd);
      browserStore.setSession("selectedDrugs", state.selectedDrugs);
    },
  },

  actions: {
    initAddOrder({ commit }) {
      let orderToAdd = browserStore.getSession("orderToAdd");
      let selectedDrugs = browserStore.getSession("selectedDrugs");

      if (orderToAdd && Array.isArray(orderToAdd.drugs)) {
        commit("setOrderToAdd", orderToAdd);
      }

      if (Array.isArray(selectedDrugs)) {
        commit("setSelectedDrugs", selectedDrugs);
      }
    },

    resetAddOrder({ commit }) {
      newOrderToAdd.drugs = [];
      commit("setOrderToAdd", { ...newOrderToAdd });
      commit("setSelectedDrugs", []);
    },

    addSelectedDrug({ commit }, drug) {
      commit("addSelectedDrug", drug);
    },

    setOrderToAdd({ commit }, orderToAdd) {
      commit("setOrderToAdd", orderToAdd);
    },

    setOrderToAddNum({ commit }, orderToAddNum) {
      commit("setOrderToAddNum", orderToAddNum);
    },

    removeSelectedDrug({ commit }, drugId) {
      commit("removeSelectedDrug", drugId);
    },

    addToCart({ commit }, drug) {
      commit("addToCart", drug);
    },
  },

  getters: {
    getSelectedDrugs: (state) => state.selectedDrugs,
    getOrderToAdd: (state) => state.orderToAdd,
    getOrderToAddNum: (state) => state.orderToAddNum,
  },
};
