import browserStore from "../service/browserStore.js";
// import axios from "../service/axios";
// import i18n from "../i18n";

export default {
  state: {
    user: {
      user: {
        groups: [],
      },
    },
  },

  mutations: {
    setUser(state, user) {
      state.user = user;
      browserStore.setUser(state.user);
    },
  },

  actions: {
    async setUser({ commit }, user) {
      let local = user ? user : browserStore.getUser();

      if (
        local &&
        local.token &&
        Array.isArray(local.user.groups) &&
        local.user.groups.length
      ) {
        commit("setUser", local);
        return true;
      }

      return false;
    },
  },

  getters: {
    getUser: (state) => state.user,
  },
};
