import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import User from "./User.js";
import StoreOrders from "./StoreOrders.js";
import AddOrder from "./AddOrder.js";
import Chat from "./Chat.js";
import DefaultRegion from "./DefaultRegion.js";

export default new Vuex.Store({
  modules: [User, StoreOrders, AddOrder, Chat, DefaultRegion],

  state: {
    snkMsg: {
      text: "",
      color: "success",
      isShow: false,
    },
  },

  mutations: {
    setSnkMsg(state, snkMsg) {
      if (!snkMsg) snkMsg = {};
      snkMsg.color = snkMsg.color ? snkMsg.color : "red";
      snkMsg.isShow = true;
      state.snkMsg = snkMsg;
    },
  },

  actions: {
    setSnkMsg({ commit }, snkMsg) {
      commit("setSnkMsg", snkMsg);
    },
  },

  getters: {
    getSnkMsg: (state) => state.snkMsg,
  },
});
