<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    classB: {
      type: String,
      required: false,
    },
    width: {
      type: [String, Number],
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      dateBox: false,
      date: null,
      dateFormatted: null,
    };
  },

  watch: {
    date() {
      this.dateFormatted = this.formatDateDot(this.date);
      this.$emit("input", this.formatDate(this.date));
    },
  },

  created() {
    this.date = this.value;
  },

  methods: {
    formatDateDot(date) {
      if (!date || (date && date.length > 10)) return null;
      let [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },

    formatDate(date) {
      if (!date || (date && date.length > 10)) return null;
      let [year, month, day] = date.split("-");
      return `${year}-${month}-${day}`;
    },
  },
};
</script>

<template>
  <v-menu
    :close-on-content-click="false"
    transition="scale-transition"
    v-model="dateBox"
    max-width="290"
  >
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <v-text-field
          dense
          outlined
          :class="classB"
          readonly
          :label="label"
          :style="{ width: width + 'px' }"
          hide-details
          v-model="dateFormatted"
        >
          <v-icon v-on="on" slot="append">mdi-calendar-month</v-icon>
        </v-text-field>
      </div>
    </template>
    <v-date-picker
      v-model="date"
      @input="dateBox = false"
      first-day-of-week="1"
      scrollable
      flat
    />
  </v-menu>
</template>
