/* eslint-disable no-console */
import sysConf from "./staticConfig.js";
import browserStore from "../service/browserStore.js";
import { tel } from "../service/filters.js";
import i18n from "../i18n.js";

const notify = (msg) => {
  if (msg == true) {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(() => {
        //
      });
    }
    return;
  } else {
    const { title, body } = msg;
    let options = {
      body: body,
      badge: "/img/icons/apple-touch-icon-60x60.png",
      icon: "/img/icons/apple-touch-icon-60x60.png",
      onclick: (event) => {
        event.preventDefault();
        window.open("/home", "_target");
      },
    };

    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      // eslint-disable-next-line no-unused-vars
      const notification = new Notification(title, options);
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission((permission) => {
        if (permission === "granted") {
          // eslint-disable-next-line no-unused-vars
          const notification = new Notification(title, options);
        }
      });
    }
  }
};

export default (store) => {
  let token = browserStore.getTokenOnly();
  if (token) {
    notify(true);

    const socket = new WebSocket(sysConf.socketNotifiUri + token);
    socket.onopen = () => {
      console.log("Notification socket connected");
    };

    socket.onmessage = (event) => {
      if (event.data) {
        let data = JSON.parse(event.data);
        if (data && data.order) {
          let old = store.getters.getOrders.find((x) => x.id == data.order.id);

          if (old) {
            store.dispatch("updateOrder", data.order);
            return;
          } else if (data.event != "update_order") {
            store.dispatch("addOrder", data.order);
            let msg = `${data.order.full_name}\n${tel(data.order.phone)}`;
            notify({ title: i18n.t("New order!"), body: msg });
          }
        }
      }

      console.log("Data received from server");
    };

    // socket.onclose = event => {
    //   if (event.wasClean) {
    //     alert(
    //       `[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`
    //     );
    //   } else {
    //     alert("[close] Соединение прервано");
    //   }
    // };

    // socket.onerror = error => {
    //   alert(`[error] ${error.message}`);
    // };
  }
};
