import { render, staticRenderFns } from "./RegionDefault.vue?vue&type=template&id=48674df9&scoped=true&"
import script from "./RegionDefault.vue?vue&type=script&lang=js&"
export * from "./RegionDefault.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48674df9",
  null
  
)

export default component.exports