<script>
import YandexMapOrder from "../YandexMapOrder.vue";

export default {
  props: {
    value: {
      type: [Boolean, Number],
      required: false,
    },
    item: Array,
  },

  components: {
    YandexMapOrder,
  },

  data: (vm) => ({
    height: 80,
    width: 300,
    coords: vm.item,
  }),

  computed: {
    dialog: {
      get: (vm) => vm.value,

      set(v) {
        this.$emit("input", v);
      },
    },
  },

  mounted() {
    this.height = window.outerHeight - 230;
    this.width = window.outerWidth - 100;
  },

  methods: {
    onClose() {
      this.dialog = false;
    },
  },
};
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" :max-width="width">
      <v-card>
        <v-card-title class="pb-5 d-flex">
          <v-spacer />

          <v-btn icon @click="onClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text :style="{ height: `${height}px` }">
          <v-card elevation="5" class="w-100 h-100 ma-0 pa-0">
            <YandexMapOrder :coords="coords" v-if="coords.length" />
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
