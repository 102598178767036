<script>
import initStates from "./store/initStates.js";

import WebSocketNotifications from "./utils/WebSocketNotifications.js";
import WebSocketMessages from "./utils/WebSocketMessages.js";

export default {
  name: "App",

  components: {},

  data: () => ({
    //
  }),

  computed: {
    snkMsg: (vm) => vm.$store.getters.getSnkMsg,
    user: (vm) => vm.$store.getters.getUser,
  },

  created() {
    initStates(this.$store);
    let isDark = this.$bStore.getLocal("$vuetify.theme.dark");
    this.$vuetify.theme.dark = isDark == true;
  },

  watch: {
    user() {
      WebSocketNotifications(this.$store);
      if (this.isManager()) {
        this.$store.dispatch("initThreads");
        WebSocketMessages(this.$store);
      }
    },
  },

  mounted() {},

  methods: {
    isManager() {
      return !!this.user.user.groups.find((x) => x.name == "manager");
    },
  },
};
</script>

<template>
  <v-app>
    <router-view />

    <v-snackbar
      v-model="snkMsg.isShow"
      top
      :color="snkMsg.color"
      :timeout="20000"
    >
      <div v-html="snkMsg.text" />

      <template v-slot:action="{ attrs }">
        <v-btn
          :title="$t('close')"
          class="transform-none"
          text
          v-bind="attrs"
          @click="snkMsg.isShow = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>
