const valid = {
  req: (v) => !!v || "",
  notNoll: (v) => v > 0 || "",
  reqPhone: (v) => {
    if (v && v.trim()) {
      return v.substr(16) != "_" || "";
    } else {
      return "";
    }
  },
  // reqPhone(v) {
  //   let msg = "Введите правильный номер телефона";

  //   return (v?.trim() && v.substr(17) == "_") || "";

  //   // return `${filedName}.setCustomValidity(${filedName}.value.substr(17) == "_" ? "${msg}" : "")`;
  // }
};

import Vue from "vue";

Vue.prototype.$valid = valid;
