<script>
export default {
  components: {
    //
  },

  data: () => ({
    height: 10,
    message: "",
  }),

  computed: {
    user: (vm) => vm.$store.getters.getUser,

    messages: {
      get: (vm) => vm.$store.getters.getMessages,
      set(v) {
        this.$store.dispatch("setMessages", v);
      },
    },
  },

  created() {
    let threadId = this.$route.params.id;
    if (!threadId) {
      this.$router.go(-1);
      return;
    }

    this.$store.dispatch("removeThreadNotifi", threadId);
    this.getMessages(threadId);
  },

  watch: {
    "$route.params.id"(v) {
      this.$store.dispatch("removeThreadNotifi", v);

      this.getMessages(v);
    },
  },

  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize() {
      this.height = window.innerHeight - 130;
    },

    getMessages(v) {
      this.messages = [];
      this.$store.dispatch("initMessages", v);
    },

    onSend() {
      if (this.message?.trim()) {
        let threadId = this.$route.params.id;
        let ms = this.message;
        let msg = { message: ms, thread: threadId };

        this.$store.dispatch("sendMessages", msg).then((status) => {
          if (status) {
            let user_id = this.user.user.id;
            let created_at = this.$tools.getRealDateTime();
            this.message = "";
            let messages = [...this.messages];
            messages.push({ body: ms, user_id, created_at });
            this.messages = messages;
          }
        });
      }
    },

    getLast(msg, index) {
      let inx = index + 1;
      if (Array.isArray(this.messages) && this.messages[inx]) {
        return this.messages[inx].user_id != this.messages[index].user_id;
      }
      return true;
    },

    scrollMe(id) {
      setTimeout(() => {
        let elm = document.getElementById(id);
        if (elm) elm.scrollIntoView();
      }, 100);
    },
  },
};
</script>

<template>
  <div class="h-100">
    <div class="chat" :style="{ height: `${height}px` }">
      <div
        v-for="(msg, index) in messages"
        :key="index"
        class="messages"
        :class="user.user.id == msg.user_id ? 'mine' : 'yours'"
      >
        <div
          :id="'i' + index"
          class="message"
          :class="getLast(msg, index) ? 'last' : ''"
        >
          <template v-if="index == messages.length - 1">
            {{ scrollMe("i" + index) }}
          </template>
          <div class="pl-1 pr-8 owerflow-wrap" v-html="msg.body" />
          <div class="time">
            {{ msg.created_at | dateTime }}
          </div>
        </div>
      </div>
    </div>

    <div class="chat-input-wrapper">
      <v-text-field
        dense
        :placeholder="$t('To write a message...')"
        hide-details
        rounded
        outlined
        v-model="message"
        @keyup.enter="onSend"
        @click:append="onSend"
        append-icon="mdi-send"
      />
    </div>
  </div>
</template>

<style scoped>
.chat-input-wrapper {
  padding-top: 5px;
}

.time {
  font-size: 12px;
  text-align: right;
}

.chat {
  width: 100%;
  display: flex;
  padding: 10px;
  overflow-y: auto;
  flex-direction: row-reverse;
  flex-flow: column nowrap;
}

.chat > :first-child {
  margin-top: auto !important;
}

.messages {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.message {
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
  max-width: 90%;
}

.yours {
  align-items: flex-start;
}

.yours .message {
  margin-right: 35%;
  background-color: #eee;
  position: relative;
}

.yours .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: #eee;
  border-bottom-right-radius: 15px;
}
.yours .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
}

.mine {
  align-items: flex-end;
}

.mine .message {
  color: white;
  margin-left: 35%;
  background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%);
  background-attachment: fixed;
  position: relative;
}

.mine .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%);
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}

.mine .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
}
</style>
