import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import ru from "vuetify/lib/locale/ru";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    // themes: {
    //   light: {
    //     accent: "#9c27b0",
    //   },
    //   dark: {
    //     secondary: "#202940",
    //     accent: "#9c27b0",
    //     primary: "#1976d2",
    //     error: "#ef5b44",
    //   },
    // },
    options: { customProperties: true },
  },
  lang: {
    locales: { ru },
    current: "ru",
  },
});
