<script>
import { debounce } from "debounce";
import SearchDrugsInputList from "./SearchDrugsInputList.vue";

export default {
  props: {
    width: {
      type: Number,
      required: false,
    },
  },

  components: {
    SearchDrugsInputList,
  },

  data: () => ({
    products: [],
    searchMenu: false,
    loading: false,
    searchText: null,
  }),

  watch: {
    searchMenu(value) {
      if (!value) {
        this.searchText = null;
        this.products = [];
      }
    },
  },

  methods: {
    onSelect(drug) {
      this.$emit("select", drug);
      this.searchMenu = false;
      this.products = [];
    },

    onTmSearchChange: debounce(function (searchText) {
      if (this.loading) return;
      this.products = [];

      if (searchText?.trim() && searchText.length > 1) {
        this.searchMenu = true;
        this.loading = true;
        this.axios.get(`drugs?search=${searchText}`).then((res) => {
          this.products = this.getArray(res.data.results);
          this.loading = false;
        });
      } else {
        this.searchMenu = false;
      }
    }, 400),

    getArray(array) {
      return Array.isArray(array) ? array : [];
    },
  },
};
</script>

<template>
  <!-- :closeOnClick="false" -->
  <v-menu
    v-if="$vuetify.breakpoint.mdAndUp"
    :closeOnContentClick="false"
    bottom
    :max-width="width"
    offsetY
    transition="slide-y-transition"
    v-model="searchMenu"
  >
    <template v-slot:activator="{}">
      <div class="py-1">
        <v-text-field
          autofocus
          rounded
          autocomplete="off"
          append-icon="mdi-magnify"
          :style="{ width: width + 'px' }"
          clearable
          flat
          dense
          hide-details
          :label="$t('Search for drugs...')"
          v-model="searchText"
          solo-inverted
          @keyup.enter="onTmSearchChange($event.target.value)"
          @input="onTmSearchChange"
        />
      </div>
    </template>

    <SearchDrugsInputList
      v-if="searchMenu"
      :products="products"
      :loading="loading"
      @selected="onSelect"
    />
  </v-menu>

  <v-dialog
    v-else
    v-model="searchMenu"
    persistent
    :retain-focus="false"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn dark icon v-on="on">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary" class="mobile-toolbar">
        <v-text-field
          autofocus
          autocomplete="off"
          flat
          hide-details
          :label="$t('Search for drugs...')"
          append-icon="mdi-magnify"
          solo-inverted
          dense
          fullWidth
          v-model="searchText"
          @input="onTmSearchChange"
        />

        <v-btn dark icon @click="searchMenu = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="pt-10 mt-4">
        <SearchDrugsInputList
          :products="products"
          :loading="loading"
          @selected="onSelect"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.mobile-toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}
</style>
