<script>
import { loadYmap } from "vue-yandex-maps";
import sysConfig from "../../utils/staticConfig.js";

import ModalsTemplate from "../modals-template.vue";

import WebSocketDrivers from "../../utils/WebSocketDrivers.js";

export default {
  components: { ModalsTemplate },

  data: () => ({
    model: true,

    isLoading: false,

    settings: {
      apiKey: sysConfig.yandexApi,
      lang: "ru_RU",
      coordorder: "latlong",
    },
    objSettings: {
      clusterize: false,
      gridSize: 32,
      clusterDisableClickZoom: false,
      geoObjectOpenBalloonOnClick: false,
      clusterOpenBalloonOnClick: false,
    },

    drivers: [],

    objectManager: null,

    socket: null,
  }),

  watch: {
    model(v) {
      if (!v) {
        setTimeout(() => {
          this.$emit("close", v);
        }, 200);
      }
    },
  },

  mounted() {
    this.init();

    try {
      WebSocketDrivers(
        (item) => {
          this.reSetItems(item);
        },
        (socket) => (this.socket = socket)
      );
    } catch (error) {
      console.log(error);
    }
  },

  beforeDestroy() {
    this.socket?.close();
  },

  methods: {
    async init() {
      this.isLoading = true;
      this.drivers = [];
      try {
        const res = await this.axios.get("driver");
        if (Array.isArray(res?.data?.results)) {
          await loadYmap({ ...this.settings, debug: false });
          this.drivers = res.data.results;
          this.isLoading = false;
          setTimeout(() => {
            this.setItems();
          }, 300);
        }
      } catch (error) {
        console.log(error);
      }

      this.isLoading = false;
    },

    reSetItems(driver) {
      if (driver?.id && this.objectManager) {
        this.objectManager?.removeAll();

        if (this.drivers.find((x) => x.id == driver.id)) {
          this.drivers = this.drivers.map((x) => {
            if (x.id == driver.id) return driver;
            return x;
          });
        } else {
          this.drivers.push(driver);
        }

        this.$nextTick(() => {
          let markers = this.getMarkers();
          this.objectManager.add(markers);
        });
      }
    },

    setItems() {
      try {
        const ymaps = window.ymaps;
        this.$refs.ymap.style.height = `${window.innerHeight - 200}px`;
        const myMap = this.createMap(ymaps);
        this.objectManager = new ymaps.ObjectManager(this.objSettings);
        myMap.geoObjects.add(this.objectManager);
        myMap.container.fitToViewport();

        let markers = this.getMarkers();
        this.objectManager.add(markers);
      } catch (error) {
        console.log(error);
      }
    },

    createMap(ymaps) {
      const myMap = new ymaps.Map("yandex-map-drivers", {
        center: [41.470268, 64.437821],
        zoom: 5.5,
        controls: ["geolocationControl", "typeSelector", "fullscreenControl"],
      });

      return myMap;
    },

    getMarkers() {
      const features = this.drivers.map((x, inx) => this.getFeature(x, inx));
      return {
        type: "FeatureCollection",
        features,
      };
    },

    getFeature(params) {
      const coordinates = [params.latitude, params.longitude];
      const driver = params.driver;

      return {
        type: "Feature",
        id: params.id,
        geometry: {
          type: "Point",
          coordinates,
        },
        properties: {
          iconCaption: this.$tel(driver.username),
          hintContent: `${driver.first_name} ${driver.last_name}`,
        },

        options: {
          preset: "islands#redIcon",
        },
      };
    },
  },
};
</script>

<template>
  <modals-template
    hideActions
    :width="isLoading ? 500 : 1500"
    v-model="model"
    :title="$t('')"
  >
    <div v-if="!isLoading" class="wrap" ref="ymap">
      <div id="yandex-map-drivers" class="h-100 w-100" />
    </div>
    <div v-else class="pa-5">
      {{ $t("Loading...") }}
      <v-progress-linear indeterminate class="mt-3" />
    </div>
  </modals-template>
</template>

<style scoped lang="scss">
.wrap {
  border-radius: 4px;
  overflow: hidden;
  padding: 20px 10px;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}
</style>
