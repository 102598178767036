<script>
export default {
  data: () => ({
    loading: false,

    isShowPassword: false,

    auth: {
      login: "",
      password: "",
    },
  }),

  // created() {
  //   if (process.env.NODE_ENV === "development") {
  //     this.auth = {
  //       login: "nurpolat",
  //       password: "Nurpolat@123."
  //     };
  //   }
  // },

  mounted() {
    let user = this.$store.getters.getUser;
    let bUser = this.$bStore.getUser();
    if (user && user.token && bUser && bUser.token) {
      this.$router.replace("/home");
    }
  },

  methods: {
    doLogin() {
      if (!this.$refs.loginForm.validate()) return;
      this.loading = true;

      this.axios
        .post("login", this.auth)
        .then((res) => {
          if (
            Array.isArray(res.data.user.groups) &&
            res.data.user.groups.length
          ) {
            this.$store.dispatch("setUser", res.data);
            setTimeout(() => {
              let isManager = res.data.user.groups.find(
                (x) => x.name == "manager"
              );

              this.$router.replace(isManager ? "/searchDrugs" : "/home");
            }, 100);
          } else {
            this.$store.dispatch("setSnkMsg", {
              text: this.$t("You have no permissions!"),
              color: "error",
            });
          }
        })
        .catch((error) => {
          let errMsg = this.$t("wrongLoginOrPassword");
          let errRes = error.response;
          if (errRes && errRes && errRes.status != 403) {
            errMsg = error.response.data;
          }

          this.$store.dispatch("setSnkMsg", {
            text: errMsg,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<template>
  <v-container class="loginBg" fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md5 lg3>
        <v-card class="position-relative pa-3">
          <v-card-text>
            <v-form ref="loginForm" lazy-validation>
              <div class="pt-5"></div>
              <v-text-field
                outlined
                autofocus
                required
                autocomplete="off"
                prepend-inner-icon="mdi-account"
                name="login"
                :rules="[$valid.req]"
                :label="$t('login')"
                type="text"
                v-model="auth.login"
              />

              <v-text-field
                outlined
                required
                autocomplete="off"
                prepend-inner-icon="mdi-lock"
                :append-icon="`mdi-eye${isShowPassword ? '-off' : ''}`"
                :class="isShowPassword ? '' : 'security_square'"
                @click:append="isShowPassword = !isShowPassword"
                name="password"
                :label="$t('password')"
                id="password"
                :rules="[$valid.req]"
                type="text"
                v-model="auth.password"
                @keyup.enter="doLogin"
              />
            </v-form>
          </v-card-text>

          <div class="d-flex px-2 pb-2">
            <v-btn
              block
              min-width="150"
              height="45"
              color="primary"
              @click="doLogin"
              :loading="loading"
              elevation="0"
            >
              {{ $t("entrance") }}
            </v-btn>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<style lang="scss" scoped>
.loginBg {
  background-image: url("../../assets/img/so-white.png");
  // background-image: url("../../assets/img/repeated-square.png");
  background-repeat: repeat;
}

.theme--dark .loginBg {
  background-image: url("../../assets/img/dark-honeycomb.png");
  // background-image: url("../../assets/img/repeated-square-dark.png");
}
</style>
