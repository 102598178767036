<script>
export default {
  components: {
    //
  },

  data: () => ({
    //
  }),
};
</script>

<template>
  <div class="wrap">
    <div>
      {{ $t("Choose who to write to!") }}
    </div>
  </div>
</template>

<style scoped>
.wrap {
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.wrap div {
  padding: 0 10px;
  background: #5a5a5a85;
  border-radius: 20px;
  color: white;
  font-weight: 300;
  font-size: 14px;
  user-select: none;
}
</style>
